<template>
  <v-container>
    <transition name="fade">
      <v-row v-show="show">
        <!-- Change sm to four for three card -->
        <v-col>
          <v-card
            :loading="loading"
            class="mr-6 ml-6 make-transparant"
            width="auto"
            elevation="5"
            outlined
            shaped
          >
            <v-parallax height="180" :src="require('@/assets/divider-bg.jpg')">
              <v-row class="text-center">
                <v-col class="mt-8">
                  <h2 class="display-2 font-weight-bold mb-1">Employees</h2>
                  <hr />
                  <p class="subheading font-weight-regular">These are our employees.</p>
                </v-col>
              </v-row>
            </v-parallax>
          </v-card>
          <v-row class="text-center transparent">
            <v-col class="mt-4 mb-4">
              <v-card class="mx-auto" max-width="320px" elevation="5">
                <v-img height="100%" src="@/assets/codingwallpaper.jpg"></v-img>
                <v-col>
                  <v-avatar size="90" style="position: absolute; top: 60px">
                    <v-img src="@/assets/antoine.jpg"></v-img>
                  </v-avatar>
                </v-col>
                <v-list-item color="rgba(0, 0, 0, .5)">
                  <v-list-item-content>
                    <v-list-item-title class="title">Antoine Engelen</v-list-item-title>
                    <v-list-item-subtitle>Owner, Developer & IT-consultant</v-list-item-subtitle>
                    <v-list-item-subtitle justify="center">
                      <v-btn
                        elevation="1"
                        rounded
                        router-link
                        to="/employees/antoine"
                        class="mb-1 mt-1"
                      >
                        More
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- <div class="dropshadow" v-show="show" >
      <v-parallax height="200" :src="require('@/assets/divider-bg.jpg')">
        <v-img :src="require('@/assets/logo.png')" contain height="200"/>
      </v-parallax>
      </div> -->
    </transition>
  </v-container>
</template>

<!-- <v-container>
    <transition name="fade">
      <div class="divider dropshadow" v-if="show">
        <div class="overlay">
          <div class="container">
            <v-row class="text-center">
              <v-col class="mb-4">
                <h2 class="display-2 font-weight-bold mb-3">About</h2>
                <hr />
                <p class="subheading font-weight-regular">
                  some things about our past...
                </p>
              </v-col>
            </v-row>
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h3 class="title mb-4">Company History</h3>
                <p class="ml-2 text-left font-weight-regular">
                  There is no company history. We just started the company.
                </p>
                <p class="ml-2 text-left font-weight-regular">
                  But there is history with the employees that have worked in the ICT and
                  development business for years.
                </p>
                <p class="ml-2 text-left font-weight-regular">
                  The lack of history makes that we we have alot of fresh ideas. But there
                  is also experincy with being an employee for other companies.
                </p>
                <p class="ml-2 text-left font-weight-regular">
                  For example we have experience in the next few things and more.
                </p>
              </div>
              <div class="col-md-6 col-sm-6">
                <span class="text-uppercase text-h7 font-weight-bold">BOOTSTRAP</span>
                <v-progress-linear v-model="bootstrap" rounded striped height="20">
                  <strong>{{ Math.ceil(bootstrap) }}%</strong>
                </v-progress-linear>
                <span class="text-uppercase text-h7 font-weight-bold"
                  >HTML &AMP; CSS</span
                >
                <v-progress-linear v-model="HTML" rounded striped height="20">
                  <strong>{{ Math.ceil(HTML) }}%</strong>
                </v-progress-linear>
                <span class="text-uppercase text-h7 font-weight-bold">Javascript</span>
                <v-progress-linear v-model="javascript" rounded striped height="20">
                  <strong>{{ Math.ceil(javascript) }}%</strong>
                </v-progress-linear>
                <span class="text-uppercase text-h7 font-weight-bold">PHP</span>
                <v-progress-linear v-model="php" rounded striped height="20">
                  <strong>{{ Math.ceil(php) }}%</strong>
                </v-progress-linear>
                <span class="text-uppercase text-h7 font-weight-bold"
                  >Content Management Systems</span
                >
                <v-progress-linear v-model="content" rounded striped height="20">
                  <strong>{{ Math.ceil(content) }}%</strong>
                </v-progress-linear>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </v-container> -->

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    HTML: 90,
    bootstrap: 100,
    javascript: 90,
    php: 85,
    content: 75,
    show: false,
  }),
  created: function () {
    // `this` points to the vm instance
    setTimeout(() => {
      this.show = true;
    }, 500);
  },
};
</script>
