<template>
  <transition name="fade">
  <div class="main" v-if="show">
    <div>
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
      <v-row>
        <!-- Change sm to four for three card -->
        <v-col>
          <v-card
            :loading="loading"
            class="mr-6 ml-6"
            width="auto"
            elevation="5"
            outlined
            shaped
          >
            <v-parallax height="200" :src="require('@/assets/divider-bg.jpg')">
              <v-img :src="require('@/assets/logo.png')" contain height="200" />
            </v-parallax>

            <v-card-title class="text-title text-uppercase">{{
              items[1].text
            }}</v-card-title>
            <v-card-text>
              <v-row align="center" class="mx-0">
                  <div class="text-justify">
                    <div class="col-md-12 col-sm-12">
                      <p>
                        This website uses cookies. By using this website and agreeing to
                        this policy, you consent to the AJAH-Solution use of cookies in
                        accordance with the terms of this policy.
                      </p>
                      <p>
                        Cookies are files sent by web servers to web browsers, and stored
                        by the web browsers.
                      </p>
                      <p>
                        The information is then sent back to the server each time the
                        browser requests a page from the server. This enables a web server
                        to identify and track web browsers.
                      </p>
                      <p>
                        There are two main kinds of cookies: session cookies and
                        persistent cookies. Session cookies are deleted from your computer
                        when you close your browser, whereas persistent cookies remain
                        stored on your computer until deleted, or until they reach their
                        expiry date.
                      </p>
                      <p>
                        When an appointment is made through our websites, the cookies will
                        capture the time the appointment is made, the IP address from
                        where the appointment was made, the browser used and the version
                        number of the browser used. This information will be captured
                        from your computer and stored against the appointment confirmation
                        on our systems.
                      </p>
                      <p>
                        The cookies will also be stored on your computer but will
                        automatically delete after 1 hour
                      </p>
                      <p>
                        <strong>Refusing cookies </strong>
                      </p>
                      <p>Most browsers allow you to refuse to accept cookies.</p>
                      <p>
                        In Internet Explorer, you can refuse all cookies by clicking
                        “Tools”, “Internet Options”, “Privacy”, and selecting “Block all
                        cookies” using the sliding selector.
                      </p>
                      <p>
                        In Firefox, you can adjust your cookies settings by clicking
                        “Tools”, “Options” and “Privacy”.
                      </p>
                      <p>
                        Blocking cookies will have a negative impact upon the usability of
                        some websites.
                      </p>
                      <p>
                        <strong>Legal </strong>
                      </p>
                      <p>
                        Once you have consented to the use of cookies, the AJAH-Solution
                        shall not request your consent on subsequent use of our website
                      </p>
                      <p>
                        AJAH-Solution may revise this policy from time-to-time. Please
                        check this page regularly to ensure you are familiar with the
                        current version.
                      </p>
                      <p>
                        For more information on the legislation regarding cookies, please
                        visit the Information Commissioners Office’s website at
                        <a href="https://www.ico.org.uk">www.ico.org.uk </a>
                      </p>
                    </div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
  </transition>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    //
  },
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Cookie Policy",
        disabled: true,
        href: "projects/project01",
      },
    ],
    show: false,
  }),
  created: function () {
    // `this` points to the vm instance
    setTimeout(() => {
      this.show = true;
    }, 1000);
    this.timer = -1;
  },
};
</script>
