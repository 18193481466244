<template>
  <transition name="fade">
  <div class="main" v-if="show">
    <div>
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
      <v-row>
        <!-- Change sm to four for three card -->
        <v-col>
          <v-card
            :loading="loading"
            class="mr-6 ml-6"
            width="auto"
            elevation="5"
            outlined
            shaped
          >
            <v-parallax height="200" :src="require('@/assets/divider-bg.jpg')">
              <v-img :src="require('@/assets/logo.png')" contain height="200" />
            </v-parallax>

            <v-card-title class="text-title text-uppercase">{{
              items[1].text
            }}</v-card-title>
            <v-card-text>
              <v-row align="center" class="mx-0">
                <div class="text-justify">
                  <div class="col-md-12 col-sm-12">
                    <p>
                      These terms and conditions govern your use of this website; by using
                      this website, you accept these terms and conditions in full. If you
                      disagree with these terms and conditions or any part of these terms
                      and conditions, you must not use this website.
                    </p>
                    <p>
                      This website uses cookies. By using this website and agreeing to
                      these terms and conditions, you consent to Intego Risks' use of
                      cookies in accordance with the terms of the AJAH-Solution cookies
                      policy.
                    </p>
                    <p style="font-weight: bold">License to use website</p>
                    <p>
                      Unless otherwise stated, AJAH-Solution and/or its licensors own the
                      intellectual property rights in the website and material on the
                      website. Subject to the license below, all these intellectual
                      property rights are reserved.
                    </p>
                    <p>
                      You may view, download for caching purposes only, and print pages
                      from the website for your own personal use, subject to the
                      restrictions set out below and elsewhere in these terms and
                      conditions.
                    </p>
                    <p>You must not:</p>
                    <ul>
                      <li>
                        republish material from this website (including republication on
                        another website);
                      </li>
                      <li>sell, rent or sub-license material from the website;</li>
                      <li>show any material from the website in public;</li>
                      <li>
                        reproduce, duplicate, copy or otherwise exploit material on this
                        website for a commercial purpose;
                      </li>
                      <li>edit or otherwise modify any material on the website; or</li>
                      <li>
                        redistribute material from this website (except for content
                        specifically and expressly made available for redistribution).
                      </li>
                    </ul>
                    <p style="font-weight: bold">Acceptable use</p>
                    <p>
                      You must not use this website in any way that causes, or may cause,
                      damage to the website or impairment of the availability or
                      accessibility of the website; or in any way which is unlawful,
                      illegal, fraudulent or harmful, or in connection with any unlawful,
                      illegal, fraudulent or harmful purpose or activity.
                    </p>
                    <p>
                      You must not use this website to copy, store, host, transmit, send,
                      use, publish or distribute any material which consists of (or is
                      linked to) any spyware, computer virus, Trojan horse, worm,
                      keystroke logger, rootkit or other malicious computer software.
                    </p>
                    <p>
                      You must not conduct any systematic or automated data collection
                      activities (including without limitation scraping, data mining, data
                      extraction and data harvesting) on or in relation to this website
                      without AJAH-Solution express written consent.
                    </p>
                    <p style="font-weight: bold">Restricted access</p>
                    <p>
                      Access to certain areas of this website is restricted. AJAH-Solution
                      reserves the right to restrict access to areas of this website, or
                      indeed this entire website, at AJAH-Solutions' discretion.
                    </p>
                    <p>
                      If the AJAH-Solution provides you with a user ID and password to
                      enable you to access restricted areas of this website or other
                      content or services, you must ensure that the user ID and password
                      are kept confidential.
                    </p>
                    <p>
                      AJAH-Solution may disable your user ID and password at sole
                      discretion without notice or explanation.
                    </p>
                    <p style="font-weight: bold">User content</p>
                    <p>
                      In these terms and conditions, “your user content” means material
                      (including without limitation text, images, audio material, video
                      material and audio-visual material) that you submit to this website,
                      for whatever purpose.
                    </p>
                    <p>
                      You confirm you have ownership of your user content and that no
                      third party has any Intellectual Property or moral right over your
                      user content. You grant to the AJAH-Solution a worldwide,
                      irrevocable, non-exclusive, royalty-free license to use, reproduce,
                      adapt, publish, translate and distribute your user content in any
                      existing or future media. You also grant AJAH-Solution the right to
                      sub-license these rights, and the right to bring an action for
                      infringement of these rights.
                    </p>
                    <p>
                      Your user content must not be illegal or unlawful, must not infringe
                      any third party's legal rights, and must not be capable of giving
                      rise to legal action whether against you or AJAH-Solution or a third
                      party (in each case under any applicable law).
                    </p>
                    <p>
                      You must not submit any user content to the website that is or has
                      ever been the subject of any threatened or actual legal proceedings
                      or other similar complaint.
                    </p>
                    <p>
                      AJAH-Solution reserves the right to edit or remove any material
                      submitted to this website, or stored on the AJAH-Solutions' servers,
                      or hosted or published upon this website.
                    </p>
                    <p>
                      You agree to indemnify and hold harmless AJAH-Solution regarding the
                      accuracy, appropriateness and ownership of your user content
                      including, but no limited to, claims of defamation, negligent
                      misstatement and/or Intellectual Property claims.
                    </p>
                    <p style="font-weight: bold">No warranties</p>
                    <p>
                      This website is provided “as is” without any representations or
                      warranties, express or implied. AJAH-Solution makes no
                      representations or warranties in relation to this website or the
                      information and materials provided on this website.
                    </p>
                    <p>
                      Without prejudice to the generality of the foregoing paragraph,
                      AJAH-Solution does not warrant that:
                    </p>
                    <ul>
                      <li>
                        this website will be constantly available, or available at all;
                      </li>
                      <li>
                        or the information on this website is complete, true, accurate or
                        non-misleading.
                      </li>
                    </ul>
                    <p>
                      Nothing on this website constitutes, or is meant to constitute,
                      advice of any kind. If you require advice in relation to any legal,
                      financial or medical matter you should consult an appropriate
                      professional.
                    </p>
                    <p>
                      From time to time this website may also include links to other
                      websites. These links are provided for your convenience to provide
                      further information. They do not signify that we endorse the
                      website(s). We have no responsibility for the content of the linked
                      website(s)
                    </p>
                    <p style="font-weight: bold">Limitations of liability</p>
                    <p>
                      AJAH-Solution will not be liable to you (whether under the law of
                      contact, the law of torts or otherwise) in relation to the contents
                      of, or use of, or otherwise in connection with, this website:
                    </p>
                    <ul>
                      <li>
                        to the extent that the website is provided free-of-charge, for any
                        direct loss;
                      </li>
                      <li>for any indirect, special or consequential loss; or
                        for any business losses, loss of revenue, income, profits or
                        anticipated savings, loss of contracts or business relationships,
                        loss of reputation or goodwill, or loss or corruption of
                        information or data.
                      </li>
                    </ul>
                    <p>
                      These limitations of liability apply even if AJAH-Solution has been
                      expressly advised of the potential loss.
                    </p>
                    <p style="font-weight: bold">Exceptions</p>
                    <p>
                      Nothing in this website disclaimer will exclude or limit any
                      warranty implied by law that it would be unlawful to exclude or
                      limit; and nothing in this website disclaimer will exclude or limit
                      AJAH-Solution liability in respect of any:
                    </p>
                    <ul>
                      <li>
                        death or personal injury caused by the AJAH-Solutions negligence;
                      </li>
                      <li>
                        or fraudulent misrepresentation on the part of AJAH-Solution or
                        matter which it would be illegal or unlawful for AJAH-Solution to
                        exclude or limit, or to attempt or purport to exclude or limit,
                        its liability.
                      </li>
                    </ul>
                    <p style="font-weight: bold">Reasonableness</p>
                    <p>
                      By using this website, you agree that the exclusions and limitations
                      of liability set out in this website disclaimer are reasonable.
                    </p>
                    <p>
                      If you do not think they are reasonable, you must not use this
                      website.
                    </p>
                    <p style="font-weight: bold">Other parties</p>
                    <p>
                      You accept that, as a limited liability entity, AJAH-Solution has an
                      interest in limiting the personal liability of its officers and
                      employees. You agree that you will not bring any claim personally
                      against AJAH-Solutions officers or employees in respect of any
                      losses you suffer in connection with the website.
                    </p>
                    <p>
                      Without prejudice to the foregoing paragraph, you agree that the
                      limitations of warranties and liability set out in this website
                      disclaimer will protect AJAH-Solutions officers, employees, agents,
                      subsidiaries, successors, assigns and sub-contractors as well as
                      AJAH-Solution.
                    </p>
                    <p style="font-weight: bold">Unenforceable provisions</p>
                    <p>
                      If any provision of this website disclaimer is, or is found to be,
                      unenforceable under applicable law, that will not affect the
                      enforceability of the other provisions of this website disclaimer.
                    </p>
                    <p style="font-weight: bold">
                      Breaches of these terms and conditions
                    </p>
                    <p>
                      Without prejudice to AJAH-Solution's other rights under these terms
                      and conditions, if you breach these terms and conditions in any way,
                      AJAH-Solution may take such action as the AJAH-Solution deems
                      appropriate to deal with the breach, including suspending your
                      access to the website, prohibiting you from accessing the website,
                      blocking computers using your IP address from accessing the website,
                      contacting your internet service provider to request that they block
                      your access to the website and/or bringing court proceedings against
                      you.
                    </p>
                    <p style="font-weight: bold">Variation</p>
                    <p>
                      AJAH-Solution may revise these terms and conditions from
                      time-to-time. Revised terms and conditions will apply to the use of
                      this website from the date of the publication of the revised terms
                      and conditions on this website. Please check this page regularly to
                      ensure you are familiar with the current version.
                    </p>
                    <p style="font-weight: bold">Severability</p>
                    <p>
                      If a provision of these terms and conditions is determined by any
                      court or other competent authority to be unlawful and/or
                      unenforceable, the other provisions will continue in effect. If any
                      unlawful and/or unenforceable provision would be lawful or
                      enforceable if part of it were deleted, that part will be deemed to
                      be deleted, and the rest of the provision will continue in effect.
                    </p>
                    <p style="font-weight: bold">Jurisdiction</p>
                    <p>
                      This agreement should be governed and interpreted exclusively
                      according to the law of England and Wales, and shall be subject to
                      the exclusive jurisdiction of the courts of England and Wales.
                    </p>
                  </div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
  </transition>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    //
  },
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Terms and Condictions",
        disabled: true,
        href: "projects/project01",
      },
    ],
    show: false,
  }),
  created: function () {
    // `this` points to the vm instance
    setTimeout(() => {
      this.show = true;
    }, 1000);
    this.timer = -1;
  },
};
</script>
