<template>
  <v-container>
    <transition name="fade">
      <div v-if="show">
        <v-row class="mt-2 mb-2 text-center">
          <v-col class="mb-4">
            <h2 class="display-2 font-weight-bold mb-3">Contact</h2>
            <hr />
            <p class="subheading font-weight-regular">
              Talk to us for more assistance. We can give more information about our employees. And work we can do for you. 
            </p>
             <p>
              If you need help with IT consulting. Developing websites or help with that. You can always contact us on the information below. 
            </p>
          </v-col>

          <v-col class="mb-5" cols="12">
            <v-row justify="center">
              <p class="text-left">
                <b>Address:</b> Sleedoorn 17, 5432 AH, Cuijk<br />
                <b>KvK. Nr.:</b> 84386789<br />
                <b>BTW. Nr.:</b> NL003957424808<br />
                <b>Email:</b>
                <a href="mailto:info@ajah-solution.nl">info@ajah-solution.nl</a><br />
                <b>Website:</b> ajah-solution.nl<br />
              </p>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </transition>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    applications: [
      {
        text: "Video-station",
        href: "/apps/video/",
      },
      {
        text: "Drive",
        href: "/apps/drive/",
      },
      {
        text: "Domoticz",
        href: "/apps/domoticz/",
      },
    ],
    show: false,
  }),
  created: function () {
    // `this` points to the vm instance
    setTimeout(() => {
      this.show = true;
    }, 500);
  },
};
</script>
