<template>
  <transition name="fade">
    <div class="main" v-if="show">
      <div>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
      </div>
      <v-container>
        <v-row>
          <!-- Change sm to four for three card -->
          <v-col>
            <v-card class="mx-auto my-1 make-transparant" elevation="5" outlined shaped>
              <v-img height="180px" src="../assets/codingwallpaper.jpg"></v-img>
              <v-col>
                  <v-avatar size="148" style="position: absolute; top: 60px">
                    <v-img src="@/assets/antoine.jpg"></v-img>
                  </v-avatar>
                </v-col>
              <v-card-title class="text-subtitle-1 text-uppercase mb-1"
                >Employee - Antoine Engelen</v-card-title
              >
              <v-card-text>
                <v-row justify="center" class="display-1 font-weight-bold mb-2">
                  Owner, Developer & IT-consultant
                </v-row>
                <v-row justify="center" class="ml-2">
                  <v-col>
                    <h2>
                      About me
                    </h2>
                    <p>
                    I was born in April 1978 and worked the last 24 years as IT network adminsitrator. And before that i worked in computershop selling and building computers after i finished school.
                    </p>
                    <p>
                    I have a calm character and I'm very motivated in doing my work. I have a allround knowledge about information technologies. And learning every day new things in the moving world of computers and netwerorks. I'm pretty good in explaining difficult technology in simpler language so that other people can understand it.
                    </p>
                    <p>                    
                    Now a days I like developing webbased application. So i'm doing a study to get my bachelor’s degree from university in information technology as proof that i can do this well. But I already have experience working for the government for almost 24 years in information and communication technology. During those years I tried to automatize a lot of repeating tasks with tools available. Running a home network since I was young so to keep up to date with new technologies. 
                  </p>
                  <p>

                  </p>
                  <h2>
                      Knowledge
                    </h2>
                  <p>
                    Have experience with SQL databases, Java, Javascript, PHP, Python, Swift, BASH, ASP, HTML, CSS, all kinds of Linux en Microsoft distributions.
                  </p>
                  <h2>
                      Examples
                    </h2>
                  <p>
                    The projects om the homepage are made by me. Energy Information is a webbased interface that connects to my own server that does a convertion from different devices. The Rocket-launch make use a the free part of a API of <a href="https://RocketLaunch.Live">RocketLaunch.Live</a>.
                  </p>
                  <p>
                  <code>
                    The examples are still under-constructions. More to come.
                  </code>
                </p>
                  <h2>
                      Contact
                    </h2>
                  <p>
                    For more information about me. Or if you like more information about what I can do for you drop a email on this address <a href="mailto:info@ajah-solution.nl">info@ajah-solution.nl</a>.
                  </p>
                  </v-col>
                  </v-row>
                                <!-- <v-row justify="center" class="display-1 font-weight-bold mb-2">
                  <v-col>
                    test
                  </v-col>
                  <v-col>
                    test
                  </v-col>
                </v-row>
                <v-row justify="center" class="display-1 font-weight-bold mb-2">
                  Working on adding this content
                </v-row> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
// import Services from "../components/Services";
// import About from "../components/About";
// import Employees from "../components/Employees";
// import Contact from "../components/Contact";
// import Projects from "../components/Projects";
// import CodeExamples from "../components/CodeExamples";
// import LogoSlogan from '../components/LogoSlogan';

export default {
  name: "Home",
  components: {
    // Services,
    // About,
    // Employees,
    // Projects,
    // CodeExamples,
    // Contact,
    // LogoSlogan,
  },
  created: function () {
    // `this` points to the vm instance
    setTimeout(() => {
      this.show = true;
    }, 1000);
  },
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Employee - Antoine Engelen",
        disabled: true,
        href: "employee/antoine",
      },
    ],
    show: false,
  }),
};
</script>
