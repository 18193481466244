<template>
  <v-container>
    <transition name="fade">
      <v-row v-show="show">
        <!-- Change sm to four for three card -->
        <v-col>
          <v-card
            :loading="loading"
            class="mr-6 ml-6 make-transparant"
            width="auto"
            elevation="5"
            outlined
            shaped
          >
            <v-parallax height="180" :src="require('@/assets/divider-bg.jpg')">
              <v-row class="text-center">
                <v-col class="mt-8">
                  <h2 class="display-2 font-weight-bold mb-1">About</h2>
                  <hr />
                  <p class="subheading font-weight-regular">
                    some things about our past...
                  </p>
                </v-col>
              </v-row>
            </v-parallax>
            <v-card-text>
              <v-row class="text-center transparent">
                <!-- start service -->
                <section id="service">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <div class="media">
                          <h3 class="title mb-4">Company History</h3>
                          <p class="ml-2 text-left font-weight-regular">
                            There is no company history. We just started the company.
                          </p>
                          <p class="ml-2 text-left font-weight-regular">
                            But there is history with the employees that have worked in
                            the ICT and development business for years.
                          </p>
                          <p class="ml-2 text-left font-weight-regular">
                            The lack of history makes that we we have alot of fresh ideas.
                            But there is also experincy with being an employee for other
                            companies.
                          </p>
                          <p class="ml-2 text-left font-weight-regular">
                            For example we have experience in the next few things and
                            more.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <div class="media">
                          <h3 class="title mb-4">Skills</h3>

                          <span class="text-uppercase text-h7 font-weight-bold"
                            >BOOTSTRAP</span
                          >
                          <v-progress-linear
                            v-model="bootstrap"
                            rounded
                            striped
                            height="20"
                          >
                            <strong>{{ Math.ceil(bootstrap) }}%</strong>
                          </v-progress-linear>
                          <span class="text-uppercase text-h7 font-weight-bold"
                            >HTML &AMP; CSS</span
                          >
                          <v-progress-linear v-model="HTML" rounded striped height="20">
                            <strong>{{ Math.ceil(HTML) }}%</strong>
                          </v-progress-linear>
                          <span class="text-uppercase text-h7 font-weight-bold"
                            >Javascript</span
                          >
                          <v-progress-linear
                            v-model="javascript"
                            rounded
                            striped
                            height="20"
                          >
                            <strong>{{ Math.ceil(javascript) }}%</strong>
                          </v-progress-linear>
                          <span class="text-uppercase text-h7 font-weight-bold">PHP</span>
                          <v-progress-linear v-model="php" rounded striped height="20">
                            <strong>{{ Math.ceil(php) }}%</strong>
                          </v-progress-linear>
                          <span class="text-uppercase text-h7 font-weight-bold"
                            >Content Management Systems</span
                          >
                          <v-progress-linear
                            v-model="content"
                            rounded
                            striped
                            height="20"
                          >
                            <strong>{{ Math.ceil(content) }}%</strong>
                          </v-progress-linear>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- end service -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- <div class="dropshadow" v-show="show" >
      <v-parallax height="200" :src="require('@/assets/divider-bg.jpg')">
        <v-img :src="require('@/assets/logo.png')" contain height="200"/>
      </v-parallax>
      </div> -->
    </transition>
  </v-container>
</template>

<!-- <v-container>
    <transition name="fade">
      <div class="divider dropshadow" v-if="show">
        <div class="overlay">
          <div class="container">
            <v-row class="text-center">
              <v-col class="mb-4">
                <h2 class="display-2 font-weight-bold mb-3">About</h2>
                <hr />
                <p class="subheading font-weight-regular">
                  some things about our past...
                </p>
              </v-col>
            </v-row>
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h3 class="title mb-4">Company History</h3>
                <p class="ml-2 text-left font-weight-regular">
                  There is no company history. We just started the company.
                </p>
                <p class="ml-2 text-left font-weight-regular">
                  But there is history with the employees that have worked in the ICT and
                  development business for years.
                </p>
                <p class="ml-2 text-left font-weight-regular">
                  The lack of history makes that we we have alot of fresh ideas. But there
                  is also experincy with being an employee for other companies.
                </p>
                <p class="ml-2 text-left font-weight-regular">
                  For example we have experience in the next few things and more.
                </p>
              </div>
              <div class="col-md-6 col-sm-6">
                <span class="text-uppercase text-h7 font-weight-bold">BOOTSTRAP</span>
                <v-progress-linear v-model="bootstrap" rounded striped height="20">
                  <strong>{{ Math.ceil(bootstrap) }}%</strong>
                </v-progress-linear>
                <span class="text-uppercase text-h7 font-weight-bold"
                  >HTML &AMP; CSS</span
                >
                <v-progress-linear v-model="HTML" rounded striped height="20">
                  <strong>{{ Math.ceil(HTML) }}%</strong>
                </v-progress-linear>
                <span class="text-uppercase text-h7 font-weight-bold">Javascript</span>
                <v-progress-linear v-model="javascript" rounded striped height="20">
                  <strong>{{ Math.ceil(javascript) }}%</strong>
                </v-progress-linear>
                <span class="text-uppercase text-h7 font-weight-bold">PHP</span>
                <v-progress-linear v-model="php" rounded striped height="20">
                  <strong>{{ Math.ceil(php) }}%</strong>
                </v-progress-linear>
                <span class="text-uppercase text-h7 font-weight-bold"
                  >Content Management Systems</span
                >
                <v-progress-linear v-model="content" rounded striped height="20">
                  <strong>{{ Math.ceil(content) }}%</strong>
                </v-progress-linear>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </v-container> -->

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    HTML: 90,
    bootstrap: 100,
    javascript: 90,
    php: 85,
    content: 75,
    show: false,
  }),
  created: function () {
    // `this` points to the vm instance
    setTimeout(() => {
      this.show = true;
    }, 500);
  },
};
</script>
