<template>
  <div class="main">
      <LogoSlogan />
      <Services />
      <Projects />
      <About />
      <CodeExamples />
      <Employees />
      <Contact />
  </div>
</template>

<script>
// @ is an alias to /src
// import Services from "../components/Services";
import About from "../components/About";
import Employees from "../components/Employees";
import Contact from "../components/Contact";
import Projects from "../components/Projects";
import CodeExamples from "../components/CodeExamples";
import LogoSlogan from '../components/LogoSlogan';

export default {
  name: 'Home',
  components: {
    // Services,
    About,
    Employees,
    Projects,
    CodeExamples,
    Contact,
    LogoSlogan,
  },
}
</script>
