<template>
  <v-container>
    <transition name="fade">
      <v-row v-show="show">
        <!-- Change sm to four for three card -->
        <v-col>
          <v-card
            :loading="loading"
            class="mr-6 ml-6 make-transparant"
            width="auto"
            elevation="5"
            outlined
            shaped
          >
            <v-parallax height="200" :src="require('@/assets/divider-bg.jpg')">
              <v-img :src="require('@/assets/logo.png')" contain height="200" />
            </v-parallax>
            <v-card-text>
              <v-row class="text-center transparent">
                <v-col class="mb-4">
                  <h2 class="display-2 font-weight-bold mb-3">Services</h2>
                  <hr />
                  <p class="subheading font-weight-regular">
                    we specialize in web application development and other ICT services
                  </p>
                </v-col>

                <!-- start service -->
                <section id="service">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6 col-sm-6">
                        <div class="media">
                          <div>
                            <v-icon size="72px" color="primary"
                              >mdi-language-html5</v-icon
                            >
                          </div>
                          <div class="media-body">
                            <h3 class="media-heading">
                              Coding Node, Javascript, PHP, HTML &amp; CSS
                            </h3>
                            <p>
                              We do develpment of Webbased applications. For that we make
                              use of a combination of the following languages: Node,
                              Javascript, PHP, HTML &amp; CSS.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-6">
                        <div class="media">
                          <div>
                            <v-icon size="72px" color="primary">mdi-laptop</v-icon>
                          </div>
                          <div class="media-body">
                            <h3 class="media-heading">Website Re-designed</h3>
                            <p>
                              We also make and re-make websites for companies. At the
                              moment we do not make the design it self. But we can
                              implement the wishes of the customers.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <!-- end service -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- <div class="dropshadow" v-show="show" >
      <v-parallax height="200" :src="require('@/assets/divider-bg.jpg')">
        <v-img :src="require('@/assets/logo.png')" contain height="200"/>
      </v-parallax>
      </div> -->
    </transition>
  </v-container>
</template>

<script>
export default {
  name: "logo-slogan",

  data: () => ({
    applications: [
      {
        text: "Video-station",
        href: "/apps/video/",
      },
      {
        text: "Drive",
        href: "/apps/drive/",
      },
      {
        text: "Domoticz",
        href: "/apps/domoticz/",
      },
    ],
    show: false,
  }),
  created: function () {
    // `this` points to the vm instance
    setTimeout(() => {
      this.show = true;
    }, 500);
  },
};
</script>
