<template>
  <transition name="fade">
    <div class="main" v-if="show">
      <div>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
      </div>

      <v-container>
        <v-row>
          <!-- Change sm to four for three card -->
          <v-col cols="12" sm="4">
            <v-card class="mx-auto my-1 make-transparant" elevation="5" outlined shaped>
              <v-img
                height="160"
                src="@/assets/best_crop_2cbed0f47a9f57cc5ca2_SpaceX_launch.jpg"
              >
              </v-img>
              <v-card-title class="text-subtitle-1 text-uppercase mb-1"
                >Rocket Launch</v-card-title
              >
              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div class="text-justify">
                  The next 5 upcoming rocket launches in the world. There location and
                  estimated launch window time. This information is provided by
                  <a href="https://RocketLaunch.Live">RocketLaunch.Live</a>.
                  <div class="text-caption text-right text--disabled">
                    <strong> Update In:</strong>
                    {{ timerCount_01 }} sec.
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <div v-for="(event, index) in events" :key="event.sortdate">
              <v-card
                class="mx-auto my-1 mt-2 make-transparant"
                elevation="5"
                outlined
                shaped
                v-show="index == 0"
              >
                <v-card-text>
                  <div class="text-subtitle-3 mt-2">
                    <div class="text-light ml-2 mb-2">
                      <span>
                        <template
                          v-if="event.vehicle.name.toLowerCase().indexOf('heavy') >= 0"
                        >
                          <v-badge color="primary accent-4" content="HEAVY">
                            <v-icon class="mr-1">mdi-rocket-launch</v-icon>
                          </v-badge>
                        </template>
                      </span>
                      <span class="text-uppercase text-subtitle-2">Name: </span>
                      <span class="text-uppercase text-subtitle-2">{{ event.name }}</span
                      ><br />
                      <span class="text-uppercase text-subtitle-2">When:</span
                      ><span> {{ event.date_str }}</span>
                    </div>
                    <template v-if="event.providerLogo != null"
                      ><v-chip color="white" class="mr-1 mb-1"
                        ><img
                          :src="event.providerLogo"
                          :title="event.provider.name"
                          align="top"
                          :alt="event.provider.name"
                          width="120px"
                          height="16px" /></v-chip
                    ></template>
                    <v-chip color="white" class="mr-1 mb-1" v-for="event1 in event.tags" :key="event1.text">
                      {{ event1.text }}
                    </v-chip>
                    <div class="text-uppercase text-subtitle-2">Flight</div>
                    <div class="ml-2" v-if="event.suborbital">Suborbital</div>
                    <div class="ml-2" v-else>Orbital or beyond</div>
                    <div v-show="event.win_open">
                      <div class="text-uppercase text-subtitle-2">Launch Window</div>
                      <div class="ml-2">
                        <div v-show="event.win_open">
                          <span class="text-uppercase text-subtitle-2">Open:</span>
                          {{
                            format.asString(
                              "yyyy-MM-dd hh:mm UTC O",
                              new Date(event.win_open)
                            )
                          }}
                        </div>
                        <div v-show="event.win_close">
                          <span class="text-uppercase text-subtitle-2">Close:</span>
                          {{
                            format.asString(
                              "yyyy-MM-dd hh:mm UTC O",
                              new Date(event.win_close)
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div v-show="event.mission_description">
                      <div class="text-uppercase text-subtitle-2">
                        Mission Description
                      </div>
                      <div class="ml-2">
                        {{ event.mission_description }}
                      </div>
                    </div>
                    <div v-show="event.launch_description">
                      <div class="text-uppercase text-subtitle-2">Launch Description</div>
                      <div class="ml-2">
                        {{ event.launch_description }}
                      </div>
                    </div>
                    <div class="text-uppercase text-subtitle-2">Vehicle</div>
                    <div class="ml-2" v-show="event.vehicle.name">
                      <span class="text-uppercase text-subtitle-2">Name:</span>
                      {{ event.vehicle.name }}
                    </div>
                    <div class="ml-2" v-show="event.provider.name">
                      <span class="text-uppercase text-subtitle-2">Provider:</span>
                      {{ event.provider.name }}
                    </div>

                    <div class="text-uppercase text-subtitle-2">Pad</div>
                    <div class="ml-2" v-show="event.pad.name">
                      <span class="text-uppercase text-subtitle-2">Name:</span>
                      {{ event.pad.name }}
                    </div>
                    <div class="ml-2" v-show="event.pad.location.name">
                      <span class="text-uppercase text-subtitle-2">Location:</span>
                      {{ event.pad.location.name }}
                    </div>
                    <div class="ml-2" v-show="event.pad.location.country">
                      <span class="text-uppercase text-subtitle-2">Country:</span>
                      <span v-show="event.pad.location.state"
                        >{{ event.pad.location.state }},
                      </span>
                      {{ event.pad.location.country }}
                    </div>
                  </div>
                  <div v-if="weatherUpdate(event.weather_updated) != null">
                    <div class="text-uppercase text-subtitle-2">
                      Expected weather at launch
                    </div>
                    <div class="text-left ml-2 mr-1">
                      <div class="text-left text-uppercase font-weight-bold">
                        Condition
                      </div>
                      <div class="text-left text--secondary ml-2 mr-1">
                        <v-icon class="text-light">{{
                          getWeatherIcon(event.weather_condition)
                        }}</v-icon>
                        {{ weatherCondition(event.weather_condition) }}
                      </div>
                      <div class="text-left text-uppercase font-weight-bold">Temp</div>
                      <div class="text-left text--secondary ml-2 mr-1">
                        <v-icon
                          class="text-light"
                          v-if="fahrenheitToCelcius(event.weather_temp) <= 3"
                          >mdi-snowflake</v-icon
                        >
                        <v-icon class="text-light">mdi-thermometer</v-icon>
                        {{ fahrenheitToCelcius(event.weather_temp) }} °C
                      </div>
                      <div class="text-left text-uppercase font-weight-bold">Wind</div>
                      <div class="text-left text--secondary ml-2 mr-1">
                        <v-icon
                          class="text-light"
                          v-if="mphTokph(event.weather_wind_mph) >= 16"
                        >
                          mdi-weather-windy
                        </v-icon>
                        <v-icon class="text-light">mdi-windsock</v-icon>
                        {{ mphTokph(event.weather_wind_mph) }} km/h
                      </div>
                      <div
                        class="text-left text--disabled text-caption text-uppercase font-weight-bold"
                      >
                        Update
                      </div>
                      <div class="text--disabled text-left text-caption ml-2 mr-1">
                        {{ event.weather_updated }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <!-- ---------------------- -->
          <v-col cols="12" sm="4">
            <div v-for="(event, index) in events" :key="event.sortdate">
              <v-card
                class="mx-auto my-1 mt-2 make-transparant"
                elevation="5"
                outlined
                shaped
                v-show="index == 1 || index == 2"
              >
                <v-card-text>
                  <div class="text-subtitle-3 mt-2">
                    <div class="text-light ml-2 mb-2">
                      <span>
                        <template
                          v-if="event.vehicle.name.toLowerCase().indexOf('heavy') >= 0"
                        >
                          <v-badge color="primary accent-4" content="HEAVY">
                            <v-icon class="mr-1">mdi-rocket-launch</v-icon>
                          </v-badge>
                        </template>
                      </span>
                      <span class="text-uppercase text-subtitle-2">Name: </span>
                      <span class="text-uppercase text-subtitle-2">{{ event.name }}</span
                      ><br />
                      <span class="text-uppercase text-subtitle-2">When:</span
                      ><span> {{ event.date_str }}</span>
                    </div>
                    <template v-if="event.providerLogo != null"
                      ><v-chip color="white" class="mr-1 mb-1"
                        ><img
                          :src="event.providerLogo"
                          :title="event.provider.name"
                          align="top"
                          :alt="event.provider.name"
                          width="120px"
                          height="16px" /></v-chip
                    ></template>
                    <v-chip color="white" class="mr-1 mb-1" v-for="event1 in event.tags" :key="event1.text">
                      {{ event1.text }}
                    </v-chip>
                    <div class="text-uppercase text-subtitle-2">Flight</div>
                    <div class="ml-2" v-if="event.suborbital">Suborbital</div>
                    <div class="ml-2" v-else>Orbital or beyond</div>
                    <div v-show="event.win_open">
                      <div class="text-uppercase text-subtitle-2">Launch Window</div>
                      <div class="ml-2">
                        <div v-show="event.win_open">
                          <span class="text-uppercase text-subtitle-2">Open:</span>
                          {{
                            format.asString(
                              "yyyy-MM-dd hh:mm UTC O",
                              new Date(event.win_open)
                            )
                          }}
                        </div>
                        <div v-show="event.win_close">
                          <span class="text-uppercase text-subtitle-2">Close:</span>
                          {{
                            format.asString(
                              "yyyy-MM-dd hh:mm UTC O",
                              new Date(event.win_close)
                            )
                          }}
                        </div>
                      </div>
                    </div>

                    <div v-show="event.mission_description">
                      <div class="text-uppercase text-subtitle-2">
                        Mission Description
                      </div>
                      <div class="ml-2">
                        {{ event.mission_description }}
                      </div>
                    </div>
                    <div v-show="event.launch_description">
                      <div class="text-uppercase text-subtitle-2">Launch Description</div>
                      <div class="ml-2">
                        {{ event.launch_description }}
                      </div>
                    </div>
                    <div class="text-uppercase text-subtitle-2">Vehicle</div>
                    <div class="ml-2" v-show="event.vehicle.name">
                      <span class="text-uppercase text-subtitle-2">Name:</span>
                      {{ event.vehicle.name }}
                    </div>
                    <div class="ml-2" v-show="event.provider.name">
                      <span class="text-uppercase text-subtitle-2">Provider:</span>
                      {{ event.provider.name }}
                    </div>

                    <div class="text-uppercase text-subtitle-2">Pad</div>
                    <div class="ml-2" v-show="event.pad.name">
                      <span class="text-uppercase text-subtitle-2">Name:</span>
                      {{ event.pad.name }}
                    </div>
                    <div class="ml-2" v-show="event.pad.location.name">
                      <span class="text-uppercase text-subtitle-2">Location:</span>
                      {{ event.pad.location.name }}
                    </div>
                    <div class="ml-2" v-show="event.pad.location.country">
                      <span class="text-uppercase text-subtitle-2">Country:</span>
                      <span v-show="event.pad.location.state"
                        >{{ event.pad.location.state }},
                      </span>
                      {{ event.pad.location.country }}
                    </div>
                  </div>
                  <div v-if="weatherUpdate(event.weather_updated) != null">
                    <div class="text-uppercase text-subtitle-2">
                      Expected weather at launch
                    </div>
                    <div class="text-left ml-2 mr-1">
                      <div class="text-left text-uppercase font-weight-bold">
                        Condition
                      </div>
                      <div class="text-left text--secondary ml-2 mr-1">
                        <v-icon class="text-light">{{
                          getWeatherIcon(event.weather_condition)
                        }}</v-icon>
                        {{ weatherCondition(event.weather_condition) }}
                      </div>
                      <div class="text-left text-uppercase font-weight-bold">Temp</div>
                      <div class="text-left text--secondary ml-2 mr-1">
                        <v-icon
                          class="text-light"
                          v-if="fahrenheitToCelcius(event.weather_temp) <= 3"
                          >mdi-snowflake</v-icon
                        >
                        <v-icon class="text-light">mdi-thermometer</v-icon>
                        {{ fahrenheitToCelcius(event.weather_temp) }} °C
                      </div>
                      <div class="text-left text-uppercase font-weight-bold">Wind</div>
                      <div class="text-left text--secondary ml-2 mr-1">
                        <v-icon v-if="mphTokph(event.weather_wind_mph) >= 16">
                          mdi-weather-windy
                        </v-icon>
                        <v-icon class="text-light">mdi-windsock</v-icon>
                        {{ mphTokph(event.weather_wind_mph) }} km/h
                      </div>
                      <div
                        class="text-left text--disabled text-caption text-uppercase font-weight-bold"
                      >
                        Update
                      </div>
                      <div class="text--disabled text-left text-caption ml-2 mr-1">
                        {{ event.weather_updated }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <!-- ---------- -->
          <v-col cols="12" sm="4">
            <div v-for="(event, index) in events" :key="event.sortdate">
              <v-card
                class="mx-auto my-1 mt-2 make-transparant"
                elevation="5"
                outlined
                shaped
                v-show="index == 3 || index == 4"
              >
                <v-card-text>
                  <div class="text-subtitle-3 mt-2">
                    <div class="text-light ml-2 mb-2">
                      <span>
                        <template
                          v-if="event.vehicle.name.toLowerCase().indexOf('heavy') >= 0"
                        >
                          <v-badge color="primary accent-4" content="HEAVY">
                            <v-icon class="mr-1">mdi-rocket-launch</v-icon>
                          </v-badge>
                        </template>
                      </span>
                      <span class="text-uppercase text-subtitle-2">Name: </span>
                      <span class="text-uppercase text-subtitle-2">{{ event.name }}</span
                      ><br />
                      <span class="text-uppercase text-subtitle-2">When:</span
                      ><span> {{ event.date_str }}</span>
                    </div>
                    <template v-if="event.providerLogo != null"
                      ><v-chip color="white" class="mr-1 mb-1"
                        ><img
                          :src="event.providerLogo"
                          :title="event.provider.name"
                          align="top"
                          :alt="event.provider.name"
                          width="120px"
                          height="16px"
                        />
                      </v-chip>
                    </template>
                    <v-chip color="white" class="mr-1 mb-1" v-for="event1 in event.tags" :key="event1.text">
                      {{ event1.text }}
                    </v-chip>
                    <div class="text-uppercase text-subtitle-2">Flight</div>
                    <div class="ml-2" v-if="event.suborbital">Suborbital</div>
                    <div class="ml-2" v-else>Orbital or beyond</div>
                    <div v-show="event.win_open">
                      <div class="text-uppercase text-subtitle-2">Launch Window</div>
                      <div class="ml-2">
                        <div v-show="event.win_open">
                          <span class="text-uppercase text-subtitle-2">Open:</span>
                          {{
                            format.asString(
                              "yyyy-MM-dd hh:mm UTC O",
                              new Date(event.win_open)
                            )
                          }}
                        </div>
                        <div v-show="event.win_close">
                          <span class="text-uppercase text-subtitle-2">Close:</span>
                          {{
                            format.asString(
                              "yyyy-MM-dd hh:mm UTC O",
                              new Date(event.win_close)
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div v-show="event.mission_description">
                      <div class="text-uppercase text-subtitle-2">
                        Mission Description
                      </div>
                      <div class="ml-2">
                        {{ event.mission_description }}
                      </div>
                    </div>
                    <div v-show="event.launch_description">
                      <div class="text-uppercase text-subtitle-2">Launch Description</div>
                      <div class="ml-2">
                        {{ event.launch_description }}
                      </div>
                    </div>
                    <div class="text-uppercase text-subtitle-2">Vehicle</div>
                    <div class="ml-2" v-show="event.vehicle.name">
                      <span class="text-uppercase text-subtitle-2">Name:</span>
                      {{ event.vehicle.name }}
                    </div>
                    <div class="ml-2" v-show="event.provider.name">
                      <span class="text-uppercase text-subtitle-2">Provider:</span>
                      {{ event.provider.name }}
                    </div>

                    <div class="text-uppercase text-subtitle-2">Pad</div>
                    <div class="ml-2" v-show="event.pad.name">
                      <span class="text-uppercase text-subtitle-2">Name:</span>
                      {{ event.pad.name }}
                    </div>
                    <div class="ml-2" v-show="event.pad.location.name">
                      <span class="text-uppercase text-subtitle-2">Location:</span>
                      {{ event.pad.location.name }}
                    </div>
                    <div class="ml-2" v-show="event.pad.location.country">
                      <span class="text-uppercase text-subtitle-2">Country:</span>
                      <span v-show="event.pad.location.state"
                        >{{ event.pad.location.state }},
                      </span>
                      {{ event.pad.location.country }}
                    </div>
                  </div>
                  <div v-if="weatherUpdate(event.weather_updated) != null">
                    <div class="text-uppercase text-subtitle-2">
                      Expected weather at launch
                    </div>
                    <div class="text-left ml-2 mr-1">
                      <div class="text-left text-uppercase font-weight-bold">
                        Condition
                      </div>
                      <div class="text-left text--secondary ml-2 mr-1">
                        <v-icon class="text-light">{{
                          getWeatherIcon(event.weather_condition)
                        }}</v-icon>
                        {{ weatherCondition(event.weather_condition) }}
                      </div>
                      <div class="text-left text-uppercase font-weight-bold">Temp</div>
                      <div class="text-left text--secondary ml-2 mr-1">
                        <v-icon
                          class="text-light"
                          v-if="fahrenheitToCelcius(event.weather_temp) <= 3"
                          >mdi-snowflake</v-icon
                        >
                        <v-icon class="text-light">mdi-thermometer</v-icon>
                        {{ fahrenheitToCelcius(event.weather_temp) }} °C
                      </div>
                      <div class="text-left text-uppercase font-weight-bold">Wind</div>
                      <div class="text-left text--secondary ml-2 mr-1">
                        <v-icon v-if="mphTokph(event.weather_wind_mph) >= 16">
                          mdi-weather-windy
                        </v-icon>
                        <v-icon class="text-light">mdi-windsock</v-icon>
                        {{ mphTokph(event.weather_wind_mph) }} km/h
                      </div>
                      <div
                        class="text-left text--disabled text-caption text-uppercase font-weight-bold"
                      >
                        Update
                      </div>
                      <div class="text--disabled text-left text-caption ml-2 mr-1">
                        {{ event.weather_updated }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </transition>
</template>

<script>
var dateFormat = require("dateformat");
var Format = require("date-format");

// @ is an alias to /src
// import http from "@/http-common";
import "@/shims/dateShim.js";
import http from "@/http-common";

export default {
  name: "rocketlaunch",
  components: {},
  data: () => ({
    format: Format,
    events: [],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Rocket Launch",
        disabled: true,
        href: "projects/project02",
      },
    ],
    loading: false,
    selection: 1,
    show: false,
    timerCount_01: 900,
  }),
  created: function () {
    // `this` points to the vm instance
    this.fetchData();
    setTimeout(() => {
      this.show = true;
    }, 1000);
    this.timer = -1;
  },
  watch: {
    timerCount_01: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount_01--;
          }, 1000);
        } else {
          this.timerCount_01 = 900;
          this.fetchData();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    weatherUpdate(weather_updated) {
      if (weather_updated == null) return null;
      return new Date(weather_updated);
    },

    fahrenheitToCelcius(weather_temp) {
      return ((weather_temp - 30) / 2).toFixed(1);
    },

    mphTokph(weather_wind_mph) {
      return (weather_wind_mph / 1.609344).toFixed(1);
    },

    weatherCondition(weather_condition) {
      if (weather_condition == null) return "Clear";
      return weather_condition;
    },

    when(value_open, value_close, item) {
      if (value_open && value_close)
        return (
          dateFormat(new Date(value_open), "Z").substr(0, 4) +
          parseInt(dateFormat(new Date(value_open), "Z").substr(4, 2)) +
          ": " +
          dateFormat(new Date(value_open), "HH:MM") +
          " - " +
          dateFormat(new Date(value_close), "HH:MM ") +
          "(" +
          dateFormat(new Date(value_open), "mmm dS, yyyy") +
          ")"
        );
      if (value_open)
        return (
          dateFormat(new Date(value_open), "Z").substr(0, 4) +
          parseInt(dateFormat(new Date(value_open), "Z").substr(4, 2)) +
          ": " +
          dateFormat(new Date(value_open), "HH:MM") +
          " (" +
          dateFormat(new Date(value_open), "mmm dS, yyyy") +
          ")"
        );
      if (!item) return "Unknown";
      if (!item.endsWith(new Date().getFullYear()))
        return this.addCurrentYearToCurrentMonth(item);
      return "Somewhere in " + item.replace(" ", ", ");
    },

    addCurrentYearToCurrentMonth(value) {
      if (
        dateFormat(new Date(), "mmm") == dateFormat(new Date(value), "mmm") &&
        value.toString().split(" ")[1] != dateFormat(new Date(), "yyyy")
      )
        return (
          dateFormat(new Date(value), "ddd, mmm dS") +
          ", " +
          dateFormat(new Date(), "yyyy")
        );
      return value.replace(" ", ", ");
    },

    getWeatherIcon(condition) {
      if (condition == "Possible Light Rain") return "mdi-weather-rainy";
      if (condition == "Partly Cloudy") return "mdi-weather-partly-cloudy";
      if (condition == "Overcast") return "mdi-weather-cloudy";
      if (condition == "Pouring") return "mdi-weather-pouring";
      if (condition == "Rain") return "mdi-weather-rainy";
      if (condition == "Possible Light Rain and Humid") return "mdi-weather-cloudy";
      if (condition == "Mostly Cloudy") return "mdi-weather-cloudy";
      if (condition == "Humid and Partly Cloudy") return "mdi-weather-cloudy";
      if (condition == "Cloudy") return "mdi-weather-cloudy";
      if (condition == "Humid") return "mdi-weather-cloudy";
      if (condition == "Clear") return "mdi-weather-sunny";
      if (condition == "Sunny") return "mdi-weather-sunny";
      if (condition == "Windy") return "mdi-weather-windy";
      if (condition == "Rain and Humid") return "mdi-weather-rainy";
      if (condition == "Windy and Mostly Cloudy") return "mdi-weather-windy-variant";
      if (condition == null) return "mdi-weather-sunny";
      return null;
    },

    providerLogo: function (provider) {
      let result = "/img/rocket/img/blank.png";
      if (provider == "rocket lab") result = "/img/rocket/img/Rocket Lab.png";
      if (provider == "nasa") result = "/img/rocket/img/nasa.png";
      if (provider == "spacex") result = "/img/rocket/img/spacex.png";
      if (provider == "roscosmos") result = "/img/rocket/img/roscosmos.png";
      if (provider == "arianespace") result = "/img/rocket/img/arianespace.png";
      if (provider == "united launch alliance (ula)")
        result = "/img/rocket/img/United Launch Alliance (ULA).png";
      if (provider == "isro") result = "/img/rocket/img/ISRO.png";
      if (provider == "blue origin") result = "/img/rocket/img/Blue Origin.png";
      if (provider == "virgin orbit") result = "/img/rocket/img/Virgin Orbit.png";
      if (provider == "china") result = "/img/rocket/img/casc.png";
      if (provider == "northrop grumman") result = "/img/rocket/img/Northrop Grumman.png";
      if (provider == "firefly") result = "/img/rocket/img/firefly.png";
      if (provider == "virgin galactic") result = "/img/rocket/img/virgin galactic.png";
      if (provider == "russian military") result = "/img/rocket/img/Russian Military.png";
      if (provider == "astra space") result = "/img/rocket/img/astra_space.png";
      if (provider == "abl space") result = "/img/rocket/img/abl_space.png";
      if (provider == "mitsubishi heavy industries")
        result = "/img/rocket/img/Mitsubishi Heavy Industries.png";
      if (provider == "relativity space") result = "/img/rocket/img/relativity_space.png";
      if (provider == "jaxa") result = "/img/rocket/img/jaxa.png";
      return result;
    },
    modifyData: function (remoteArray) {
      let localArray = [];
      remoteArray.forEach((element) => {
        let provider = element.provider.name.toLowerCase();
        element.providerLogo = this.providerLogo(provider);
        localArray.push(element);
      });
      console.log(localArray);
      return localArray;
    },
    fetchData: function () {
      let url = "rocket/";
      http.get(url).then((res) => {
        console.log(res.data.result);
        // this.events.push(elementDayNumber.replace("0", ""));
        this.events = this.modifyData(res.data.result);
      });
    },
  },
};
</script>
// apt install libapache2-mod-php php-curl

<style>
/* #app {
  background: rgb(0, 0, 0);
  background: radial-gradient(circle, rgba(129, 129, 129, 0.1) 0%, rgba(128, 127, 127, 0.1) 75%, rgba(255,255,255,0.1) 100%);
} */

/* html {
  background: url("../assets/web_first_images_release.png");
  background-color: rgba(2, 16, 26, 0.918);
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
} */
</style>
