<template>
  <v-container>
    <transition name="fade">
      <div v-if="show">
        <v-row class="mt-2 mb-2 text-center">
          <v-col class="mb-4">
            <h2 class="display-2 font-weight-bold mb-3">Projects</h2>
            <hr />
            <p class="subheading font-weight-regular">
              Some web based applications we made ourself.
            </p>
          </v-col>

          <v-col class="mb-5" cols="12">
            <v-row justify="center">
              <v-btn
                v-for="(next, i) in projects"
                :key="i"
                elevation="5"
                rounded
                x-large
                router-link
                :to="next.href"
                class="mr-4 mb-2"
              >
                <v-icon size="48px" color="primary" class="mr-4">
                  {{ next.icon }}
                </v-icon>
                {{ next.text }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </transition>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    projects: [
      {
        text: "Energy Information",
        icon: "mdi-home-lightning-bolt-outline",
        href: "/projects/project01",
      },
      {
        text: "Rocket-Launch",
        icon: "mdi-rocket-launch",
        href: "/projects/project02/",
      },
    ],
    show: false,
  }),
  created: function () {
    // `this` points to the vm instance
    setTimeout(() => {
      this.show = true;
    }, 500);
  },
};
</script>
