<template>
  <transition name="fade">
  <div class="main" v-if="show">
    <div>
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
      <v-row>
        <!-- Change sm to four for three card -->
        <v-col>
          <v-card
            :loading="loading"
            class="mr-6 ml-6"
            width="auto"
            elevation="5"
            outlined
            shaped
          >
            <v-parallax height="200" :src="require('@/assets/divider-bg.jpg')">
              <v-img :src="require('@/assets/logo.png')" contain height="200" />
            </v-parallax>

            <v-card-title class="text-title text-uppercase">{{
              items[1].text
            }}</v-card-title>
            <v-card-text>
              <v-row align="center" class="mx-0">
                <div class="text-justify">
                  <div class="col-md-12 col-sm-12">
                    <p style="font-weight: bold">Introduction</p>
                    <p>
                      Your privacy is important to AJAH-Solution. This privacy statement
                      provides information about the personal information that AJAH-Solution collects, and the ways in which AJAH-Solution uses
                      that personal information.
                    </p>
                    <p style="font-weight: bold">Personal information collection</p>
                    AJAH-Solution may collect and use the following kinds of personal
                    information:
                    <ul>
                      <li>information about your use of this website;</li>
                      <li>
                        information that you provide using for the purpose of registering
                        with the website;
                      </li>
                      <li>
                        information that you provide for the purpose of subscribing to the
                        website services; and
                      </li>
                      <li>any other information that you send to AJAH-Solution</li>
                    </ul>
                    <p style="font-weight: bold">Using personal information</p>
                    <p>AJAH-Solution may use your personal information to:</p>
                    <ul>
                      <li>administer this website;</li>
                      <li>personalise the website for you;</li>
                      <li>enable your access to and use of the website services;</li>
                      <li>publish information about you on the website;</li>
                      <li>send to you products that you purchase;</li>
                      <li>supply to you services that you purchase;</li>
                      <li>send to you statements and invoices;</li>
                      <li>collect payments from you; and</li>
                      <li>send you marketing communications.</li>
                    </ul>
                    <p>
                      Where AJAH-Solution discloses your personal information to its
                      agents or sub-contractors for these purposes, the agent or
                      sub-contractor in question will be obligated to use that personal
                      information in accordance with the terms of this privacy statement.
                    </p>
                    <p>
                      In addition to the disclosures reasonably necessary for the purposes
                      identified elsewhere above, AJAH-Solution may disclose your
                      personal information to the extent that it is required to do so by
                      law, in connection with any legal proceedings or prospective legal
                      proceedings, and in order to establish, exercise or defend its legal
                      rights.
                    </p>
                    <p style="font-weight: bold">Securing your data</p>
                    AJAH-Solution will take reasonable technical and organisational
                    precautions to prevent the loss, misuse or alteration of your personal
                    information. AJAH-Solution will store all the personal information
                    you provide [on its secure servers].
                    <p style="font-weight: bold">Updating this statement</p>
                    <p>
                      AJAH-Solution may update this privacy policy by posting a new
                      version on this website.
                    </p>
                    <p>
                      You should check this page occasionally to ensure you are familiar
                      with any changes.
                    </p>
                    <p style="font-weight: bold">Other websites</p>
                    <p>This website contains links to other websites.</p>
                    <p>
                      AJAH-Solution is not responsible for the privacy policies or
                      practices of any third party.
                    </p>
                  </div>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
  </transition>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    //
  },
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Privacy Policy",
        disabled: true,
        href: "projects/project01",
      },
    ],
    show: false,
  }),
  created: function () {
    // `this` points to the vm instance
    setTimeout(() => {
      this.show = true;
    }, 1000);
    this.timer = -1;
  },
};
</script>
