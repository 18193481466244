<template>
  <transition name="fade">
    <div class="main" v-if="show">
      <div>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
      </div>

      <v-container>
        <v-row>
          <!-- Change sm to four for three card -->
          <v-col cols="12" sm="4">
            <v-card
              class="mx-auto"
              elevation="5"
              outlined
              shaped
            >
              <template slot="progress">
                <v-progress-linear
                  color="primary"
                  height="5"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-img
                height="160"
                lazy-src="@/assets/day.jpg"
                src="@/assets/day.jpg"
                v-if="day"
              ></v-img>
              <v-img
                height="160"
                lazy-src="@/assets/night.jpg"
                src="@/assets/night.jpg"
                v-else
              ></v-img>
              <v-card-title class="text-subtitle-1 text-uppercase"
                >Energy Information</v-card-title
              >
              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div class="text-justify">
                  This card displays information over the current status of gas usages,
                  energy usages and or delivery.
                </div>
                <div class="mt-4 text-subtitle-2 text-uppercase">
                  <v-icon class="text-light mr-1">mdi-weather-sunny</v-icon>Sun
                </div>
                <div class="text-subtitle-3 mb-3">
                  <template v-if="day || !beforeSunSet">
                    <strong>Sun Rose:</strong>
                    {{ arrayItem.sunRise }}
                  </template>
                  <template v-else>
                    <strong>Sun Rise:</strong>
                    {{ arrayItem.sunRise }}
                  </template>
                  <br />
                  <template v-if="day || beforeSunSet">
                    <strong>Sun Setting:</strong>
                    {{ arrayItem.sunSet }}
                  </template>
                  <template v-else>
                    <strong>Sun Set:</strong>
                    {{ arrayItem.sunSet }}
                  </template>
                  <br />
                  <template>
                    <strong>System Time:</strong>
                    {{ arrayItem.now }}
                  </template>
                </div>
                <div class="text-subtitle-2 text-uppercase">
                  <v-icon class="text-light mr-1">mdi-flash</v-icon>Power
                </div>
                <div class="text-subtitle-3 mb-3">
                  <strong>Usage Today:</strong>
                  {{ arrayItem.power.CounterToday }}
                  <br />
                  <strong>Production Today:</strong>
                  {{ arrayItem.power.CounterProductionToday }}
                  <br />
                  <strong>Delivery Today:</strong>
                  {{ arrayItem.power.CounterDelivToday }}
                  <br />
                  <strong>Usage:</strong>
                  {{ arrayItem.power.Usage }}
                  <br />
                  <template v-if="day">
                    <strong>Production:</strong>
                    {{ arrayItem.power.UsageProduction }}
                    <br />
                    <strong>Delivery:</strong>
                    {{ arrayItem.power.UsageDeliv }}
                    <br />
                  </template>
                </div>
                <div class="text-subtitle-2 text-uppercase">
                  <v-icon class="text-light mr-1">mdi-cloud</v-icon>Gas
                </div>
                <div class="text-subtitle-3 mb-3">
                  <strong>Usage Today:</strong>
                  {{ arrayItem.gas.CounterToday }}
                </div>
                <span class="text-caption grey--text">
                  <strong>Update In:</strong>
                  {{ timerCount_01 }} sec.
                </span>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- ------------------------------ -->
          <v-col cols="12" sm="4">
            <v-card
              class="mx-auto mb-6"
              elevation="5"
              outlined
              shaped
            >
              <template slot="progress">
                <v-progress-linear
                  color="primary"
                  height="5"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-card-title class="text-subtitle-1 text-uppercase"
                >Month selector</v-card-title
              >
              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div class="text-justify">
                  Select the month for information about that month. You can get
                  information of the current month and past months.
                </div>
                <v-row align="center" class="mx-0"
                  ><v-select
                    item-text="name"
                    item-value="last"
                    v-model="defaultSelected"
                    :items="people"
                    @change="save()"
                  />
                </v-row>
              </v-card-text>
            </v-card>
            <!-- --------------- -->
            <v-card
              class="mx-auto"
              elevation="5"
              outlined
              shaped
            >
              <template slot="progress">
                <v-progress-linear
                  color="primary"
                  height="5"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-img height="160" src="@/assets/history-gas.jpg"></v-img>
              <v-card-title class="text-subtitle-1 text-uppercase"
                >Graphical Gas Information</v-card-title
              >
              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div class="text-justify">
                  This card displays information over gas usages graphical form.
                </div>
                <div
                  class="mt-3 text-subtitle-2 text-uppercase"
                  v-if="defaultSelected.last == undefined"
                >
                  <v-icon class="text-light mr-1">mdi-cloud</v-icon>Network Usage:
                  {{ people[defaultSelected - 1].name }}
                </div>
                <div class="mt-3 text-subtitle-2 text-uppercase" v-else>
                  <v-icon class="text-light mr-1">mdi-cloud</v-icon>Network Usage:
                  {{ people[defaultSelected.last - 1].name }}
                </div>
                <div class="mr-4 text-right text-text-pre">
                  <strong>Total:</strong> {{ gas.Month.toFixed(3) }} m3
                </div>
                <div class="text-subtitle-3">
                  <template>
                    <v-sparkline
                      :labels="gas.Labels"
                      :value="gas.Values"
                      :gradient="gradient"
                      :smooth="radius || false"
                      :padding="padding"
                      :line-width="width"
                      :stroke-linecap="lineCap"
                      :gradient-direction="gradientDirection"
                      :fill="fill"
                      :type="type"
                      :auto-line-width="autoLineWidth"
                      auto-draw
                      color="text--secondary"
                    ></v-sparkline>
                  </template>
                </div>
                <div class="mt-4">
                  <span class="text-caption grey--text">
                    <strong>Update In:</strong>
                    {{ timerCount_02 }} sec.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- ------------------------------ -->
          <v-col cols="12" sm="4">
            <v-card
              class="mx-auto"
              elevation="5"
              outlined
              shaped
            >
              <template slot="progress">
                <v-progress-linear
                  color="primary"
                  height="5"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-img height="160" src="@/assets/history-electric.jpg"></v-img>
              <v-card-title class="text-subtitle-1 text-uppercase"
                >Graphical Power Information</v-card-title
              >
              <v-card-text>
                <v-row align="center" class="mx-0"> </v-row>
                <div class="text-justify">
                  This card displays information over energy, production usages and or
                  delivery graphical form.
                </div>
                <div
                  class="mt-3 text-subtitle-2 text-uppercase"
                  v-if="defaultSelected.last == undefined"
                >
                  <v-icon class="text-light mr-1">mdi-flash</v-icon>Network Usage:
                  {{ people[defaultSelected - 1].name }}
                </div>
                <div class="mt-3 text-subtitle-2 text-uppercase" v-else>
                  <v-icon class="text-light mr-1">mdi-flash</v-icon>Network Usage:
                  {{ people[defaultSelected.last - 1].name }}
                </div>
                <div class="mr-4 text-right text-text-pre">
                  <strong>Total:</strong> {{ power.Month.toFixed(3) }} kWh
                </div>
                <div class="text-subtitle-3">
                  <template>
                    <v-sparkline
                      :labels="power.Labels"
                      :value="power.Values"
                      :gradient="gradient"
                      :smooth="radius || false"
                      :padding="padding"
                      :line-width="width"
                      :stroke-linecap="lineCap"
                      :gradient-direction="gradientDirection"
                      :fill="fill"
                      :type="type"
                      :auto-line-width="autoLineWidth"
                      auto-draw
                      color="text--secondary"
                    ></v-sparkline>
                  </template>
                </div>
                <!-- --------------- -->
                <!-- <div
                  class="mt-3 text-subtitle-2 text-uppercase"
                  v-if="defaultSelected.last == undefined"
                >
                  <v-icon class="text-light mr-1">mdi-solar-panel</v-icon>Solar
                  Production:
                  {{ people[defaultSelected - 1].name }}
                </div>
                <div class="mt-3 text-subtitle-2 text-uppercase" v-else>
                  <v-icon class="text-light mr-1">mdi-solar-panel</v-icon>Solar
                  Production:
                  {{ people[defaultSelected.last - 1].name }}
                </div>

                <div class="mr-4 text-right text-text-pre">
                  <strong>Total:</strong> {{ solar.Month.toFixed(3) }} kWh
                </div>
                <div class="text-subtitle-3">
                  <template>
                    <v-sparkline
                      :labels="solar.Labels"
                      :value="solar.Values"
                      :gradient="gradient"
                      :smooth="radius || false"
                      :padding="padding"
                      :line-width="width"
                      :stroke-linecap="lineCap"
                      gradient-direction="bottom"
                      :fill="fill"
                      :type="type"
                      :auto-line-width="autoLineWidth"
                      auto-draw
                      color="text--secondary"
                    ></v-sparkline>
                  </template>
                </div> -->
                <!-- ---------- -->
                <div v-if="production">
                  <div
                    class="mt-3 text-subtitle-2 text-uppercase"
                    v-if="defaultSelected.last == undefined"
                  >
                    <v-icon class="text-light mr-1">mdi-solar-panel</v-icon>Solar Production:
                    {{ people[defaultSelected - 1].name }}
                  </div>
                  <div class="mt-3 text-subtitle-2 text-uppercase" v-else>
                    <v-icon class="text-light mr-1">mdi-solar-panel</v-icon>Solar Production:
                    {{ people[defaultSelected.last - 1].name }}
                  </div>

                  <div class="mr-4 text-right text-text-pre">
                    <strong>Total:</strong> {{ solar_1.Month.toFixed(3) }} kWh
                  </div>
                  <div class="text-subtitle-3">
                    <template>
                      <v-sparkline
                        :labels="solar_1.Labels"
                        :value="solar_1.Values"
                        :gradient="gradient"
                        :smooth="radius || false"
                        :padding="padding"
                        :line-width="width"
                        :stroke-linecap="lineCap"
                        gradient-direction="bottom"
                        :fill="fill"
                        :type="type"
                        :auto-line-width="autoLineWidth"
                        auto-draw
                        color="text--secondary"
                      ></v-sparkline>
                    </template>
                  </div>
                </div>
                <!-- ---------- -->
                <div
                  class="mt-3 text-subtitle-2 text-uppercase"
                  v-if="defaultSelected.last == undefined"
                >
                  <v-icon class="text-light mr-1">mdi-flash</v-icon>Network Delivery:
                  {{ people[defaultSelected - 1].name }}
                </div>
                <div class="mt-3 text-subtitle-2 text-uppercase" v-else>
                  <v-icon class="text-light mr-1">mdi-flash</v-icon>Network Delivery:
                  {{ people[defaultSelected.last - 1].name }}
                </div>

                <div class="mr-4 text-right text-text-pre">
                  <strong>Total:</strong> {{ solar.Month.toFixed(3) }} kWh
                </div>
                <div class="text-subtitle-3">
                  <template>
                    <v-sparkline
                      :labels="solar.Labels"
                      :value="solar.Values"
                      :gradient="gradient"
                      :smooth="radius || false"
                      :padding="padding"
                      :line-width="width"
                      :stroke-linecap="lineCap"
                      gradient-direction="bottom"
                      :fill="fill"
                      :type="type"
                      :auto-line-width="autoLineWidth"
                      auto-draw
                      color="text--secondary"
                    ></v-sparkline>
                  </template>
                </div>
                <div class="mt-4">
                  <span class="text-caption grey--text">
                    <strong>Update In:</strong>
                    {{ timerCount_02 }} sec.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </transition>
</template>

<script>
var format = require("date-format");
const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "yellow"],
  ["purple", "violet"],
  ["#00c6ff", "#F0F", "#FF0"],
  ["#f72047", "#ffd200", "#1feaea"],
];
// @ is an alias to /src
// import http from "@/http-common";
import "@/shims/dateShim.js";
import http from "@/http-common";

export default {
  name: "Home",
  components: {},
  data: () => ({
    defaultSelected: {
      name: "January",
      last: "01",
    },
    people: [
      {
        name: "January",
        last: "01",
      },
      {
        name: "February",
        last: "02",
      },
      {
        name: "March",
        last: "03",
      },
      {
        name: "April",
        last: "04",
      },
      {
        name: "May",
        last: "05",
      },
      {
        name: "June",
        last: "06",
      },
      {
        name: "July",
        last: "07",
      },
      {
        name: "August",
        last: "08",
      },
      {
        name: "September",
        last: "09",
      },
      {
        name: "October",
        last: "10",
      },
      {
        name: "November",
        last: "11",
      },
      {
        name: "December",
        last: "12",
      },
    ],
    width: 2,
    radius: 10,
    padding: 8,
    lineCap: "round",
    gradient: gradients[5],
    power: [],
    gas: [],
    solar: [],
    solar_1: [],
    production: false,
    gradientDirection: "top",
    gradients,
    fill: false,
    type: "trend",
    autoLineWidth: false,
    timerCount_01: 5,
    timerCount_02: 60,
    arrayItem: {
      sunRise: "-",
      sunSet: "-",
      now: "-",
      power: {
        CounterToday: "- kWh",
        CounterDelivToday: "- kWh",
        CounterProductionToday: "- kWh",
        Usage: "- Watt",
        UsageProduction: "- Watt",
        UsageDeliv: "- Watt",
      },
      gas: {
        CounterToday: " 0.616 m3",
      },
    },
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Energy Information",
        disabled: true,
        href: "projects/project01",
      },
    ],
    loading: false,
    selection: 1,
    show: false,
    day: true,
    beforeSunSet: false,
  }),
  created: function () {
    // `this` points to the vm instance
    this.defaultSelected.last = format.asString("MM", new Date());
    this.fetchData_01();
    this.fetchData_02();
    setTimeout(() => {
      this.show = true;
    }, 1000);
    this.timer = -1;
  },
  watch: {
    timerCount_01: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount_01--;
          }, 1000);
        } else {
          this.timerCount_01 = 5;
          this.fetchData_01();
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    timerCount_02: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount_02--;
          }, 1000);
        } else {
          this.timerCount_02 = 60;
          this.fetchData_02(this.defaultSelected);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    save() {
      // console.log(this.defaultSelected);
      this.production = false;
      this.fetchData_02(this.defaultSelected);
    },
    isDay: function () {
      if (
        this.arrayItem.now.replace(":", "") > this.arrayItem.sunRise.replace(":", "") &&
        this.arrayItem.now.replace(":", "") < this.arrayItem.sunSet.replace(":", "")
      ) {
        return true;
      } else {
        return false;
      }
    },
    isBeforeSunSet: function () {
      if (this.arrayItem.now.replace(":", "") < this.arrayItem.sunSet.replace(":", "")) {
        return true;
      } else {
        return false;
      }
    },
    setPower: function (res) {
      this.arrayItem.power.CounterToday = res.data.result[0].CounterToday;
      this.arrayItem.power.CounterDelivToday = res.data.result[0].CounterDelivToday;
      this.arrayItem.power.Usage = res.data.result[0].Usage;
      this.arrayItem.power.UsageDeliv = res.data.result[0].UsageDeliv;
    },
    setGas: function (res) {
      this.arrayItem.gas.CounterToday = res.data.result[0].CounterToday;
    },
    setSun: function (res) {
      this.arrayItem.sunRise = res.data.Sunrise;
      this.arrayItem.sunSet = res.data.Sunset;
      this.arrayItem.now =
        res.data.ServerTime.split(" ")[1].split(":")[0] +
        ":" +
        res.data.ServerTime.split(" ")[1].split(":")[1];
    },
    fetchData_01: function () {
      let url = "energy/?rid=power";
      http.get(url).then((res) => {
        this.setSun(res);
      });
      url = "energy/?rid=power";
      http.get(url).then((res) => {
        this.setPower(res);
      });
      url = "energy/?rid=gas";
      http.get(url).then((res) => {
        this.setGas(res);
      });
      url = "energy/?rid=solar_1";
      http.get(url).then((res) => {
        this.arrayItem.power.CounterProductionToday = res.data.result[0].CounterToday;
      });
      url = "energy/?rid=solar_2";
      http.get(url).then((res) => {
        var lastUpdate = format.asString(
          "hh:mm",
          new Date(res.data.result[0].LastUpdate.replace(" ", "T"))
        );
        this.arrayItem.power.UsageProduction =
          res.data.result[0].Data + " (Updated: " + lastUpdate + ")";
      });
      this.day = this.isDay();
      this.beforeSunSet = this.isBeforeSunSet();
    },
    fetchData_02: function (value) {
      let url = "energy/?rid=power&period=year";
      http.get(url).then((res) => {
        this.modifyDataPower(res.data.result, value);
        this.modifyDataSolar(res.data.result, value);
      });
      url = "energy/?rid=gas&period=year";
      http.get(url).then((res) => {
        this.modifyDataGas(res.data.result, value);
      });
      url = "energy/?rid=solar_2&period=year";
      http.get(url).then((res) => {
        this.modifyDataSolar_1(res.data.result, value);
      });
    },
    calculatePowerData: function (element, currentMonthYear) {
      var elementDate = new Date(element.d);
      var elementMonthYear = format.asString("MM yyyy", elementDate);
      var elementDayNumber = format.asString("dd", elementDate);
      if (elementMonthYear == currentMonthYear) {
        this.power.Month += parseFloat(element.v) + parseFloat(element.v2);
        this.power.Labels.push(this.removeLeadingZero(elementDayNumber));
        this.power.Values.push(parseFloat(element.v) + parseFloat(element.v2));
      }
    },
    calculateGasData: function (element, currentMonthYear) {
      var elementDate = new Date(element.d);
      var elementMonthYear = format.asString("MM yyyy", elementDate);
      var elementDayNumber = format.asString("dd", elementDate);
      if (elementMonthYear == currentMonthYear) {
        this.gas.Month += parseFloat(element.v);
        this.gas.Labels.push(this.removeLeadingZero(elementDayNumber));
        this.gas.Values.push(parseFloat(element.v));
      }
    },
    calculatePowerSolarData: function (element, currentMonthYear) {
      var elementDate = new Date(element.d);
      var elementMonthYear = format.asString("MM yyyy", elementDate);
      var elementDayNumber = format.asString("dd", elementDate);
      if (elementMonthYear == currentMonthYear) {
        this.solar.Month += parseFloat(element.r1) + parseFloat(element.r2);
        this.solar.Labels.push(this.removeLeadingZero(elementDayNumber));
        this.solar.Values.push(parseFloat(element.r1) + parseFloat(element.r2));
      }
    },
    calculateSolarData_1: function (element, currentMonthYear) {
      var elementDate = new Date(element.d);
      var elementMonthYear = format.asString("MM yyyy", elementDate);
      var elementDayNumber = format.asString("dd", elementDate);
      if (elementMonthYear == currentMonthYear) {
        this.production = true;

        this.solar_1.Month += parseFloat(element.v);
        this.solar_1.Labels.push(this.removeLeadingZero(elementDayNumber));
        this.solar_1.Values.push(parseFloat(element.v));
      }
    },
    removeLeadingZero: function (value) {
      if (value >= 10) {
        return value;
      } else {
        return value.replace("0", "");
      }
    },
    modifyDataPower: function (remoteArray, value) {
      this.power.Labels = [" "];
      this.power.Values = [0];
      this.power.Month = null;
      var currentYear, currentMonth;
      if (this.defaultSelected.last == undefined) {
        currentYear = format.asString("yyyy", new Date());
        currentMonth = value;
      } else {
        currentYear = format.asString("yyyy", new Date());
        currentMonth = format.asString("MM", new Date()); //"01";
      }
      if (currentMonth > format.asString("MM", new Date())) {
        currentYear = format.asString("yyyy", new Date()) - 1;
      }
      var currentMonthYear = currentMonth + " " + currentYear;
      remoteArray.forEach((element) => {
        this.calculatePowerData(element, currentMonthYear);
      });
      if (currentMonth == format.asString("MM", new Date())) {
        this.addEmptyPartToSparkline(this.power);
      }
    },
    modifyDataSolar: function (remoteArray, value) {
      this.solar.Labels = [" "];
      this.solar.Values = [0];
      this.solar.Month = null;
      var currentYear, currentMonth;
      if (this.defaultSelected.last == undefined) {
        currentYear = format.asString("yyyy", new Date());
        currentMonth = value;
      } else {
        currentYear = format.asString("yyyy", new Date());
        currentMonth = format.asString("MM", new Date()); //"01";
      }
      if (currentMonth > format.asString("MM", new Date())) {
        currentYear = format.asString("yyyy", new Date()) - 1;
      }
      // console.log(currentYear);
      // console.log(currentMonth);
      var currentMonthYear = currentMonth + " " + currentYear;
      remoteArray.forEach((element) => {
        this.calculatePowerSolarData(element, currentMonthYear);
      });
      if (currentMonth == format.asString("MM", new Date())) {
        this.addEmptyPartToSparkline(this.solar);
      }
    },
    modifyDataGas: function (remoteArray, value) {
      this.gas.Labels = [" "];
      this.gas.Values = [0];
      this.gas.Month = null;
      var currentYear, currentMonth;
      if (this.defaultSelected.last == undefined) {
        currentYear = format.asString("yyyy", new Date());
        currentMonth = value;
      } else {
        currentYear = format.asString("yyyy", new Date());
        currentMonth = format.asString("MM", new Date()); //"01";
      }
      if (currentMonth > format.asString("MM", new Date())) {
        currentYear = format.asString("yyyy", new Date()) - 1;
      }
      // console.log(currentYear);
      // console.log(currentMonth);
      var currentMonthYear = currentMonth + " " + currentYear;
      remoteArray.forEach((element) => {
        this.calculateGasData(element, currentMonthYear);
      });
      if (currentMonth == format.asString("MM", new Date())) {
        this.addEmptyPartToSparkline(this.gas);
      }
    },
    modifyDataSolar_1: function (remoteArray, value) {
      this.solar_1.Labels = [" "];
      this.solar_1.Values = [0];
      this.solar_1.Month = null;
      var currentYear, currentMonth;
      if (this.defaultSelected.last == undefined) {
        currentYear = format.asString("yyyy", new Date());
        currentMonth = value;
      } else {
        currentYear = format.asString("yyyy", new Date());
        currentMonth = format.asString("MM", new Date()); //"01";
      }
      if (currentMonth > format.asString("MM", new Date())) {
        currentYear = format.asString("yyyy", new Date()) - 1;
      }
      console.log(currentYear);
      console.log(currentMonth);
      var currentMonthYear = currentMonth + " " + currentYear;
      if (
        format.asString("dd", new Date(remoteArray[0].d)) > 1 &&
        currentMonthYear == format.asString("MM yyyy", new Date(remoteArray[0].d))
      ) {
        console.log(format.asString("dd", new Date(remoteArray[0].d)));
        console.log("true");
        this.production = true;
        for (var i = 0; i < format.asString("dd", new Date(remoteArray[0].d)); i++) {
          console.log(i);
          this.solar_1.Labels.push(i);
          this.solar_1.Values.push(0);
        }
      }
      console.log("-----");
      remoteArray.forEach((element) => {
        this.calculateSolarData_1(element, currentMonthYear);
      });
      if (currentMonth == format.asString("MM", new Date())) {
        this.addEmptyPartToSparkline(this.solar_1);
      }
    },
    addEmptyPartToSparkline: function (item) {
      var currentDayNumber = parseInt(format.asString("dd", new Date()));
      var currentDaysinMonth = new Date().getDaysInMonth();
      for (
        var dayCounter = currentDayNumber + 1;
        dayCounter <= currentDaysinMonth;
        dayCounter++
      ) {
        item.Labels.push(dayCounter);
        item.Values.push(0);
      }
    },
  },
};
</script>
// apt install libapache2-mod-php php-curl
